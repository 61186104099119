import React, { useContext, useState } from "react";
import { OrganizationContext } from "../../context/OrganizationContext";
import OrganizationLogoUpload from "./OrganizationLogoUpload";
import { useTranslation } from "react-i18next";
import InputField from "../utilities/InputField"; // Assurez-vous que le chemin est correct

const OrganizationSettings = () => {
  const { t } = useTranslation();
  const { organization, updateOrganization } = useContext(OrganizationContext);
  const [formData, setFormData] = useState({ ...organization });

  // Gérer les changements de champs et du logo
  const handleChange = ({ target: { name, value } }) =>
    setFormData((prev) => ({ ...prev, [name]: value }));

  const handleLogoChange = (logoUrl) =>
    setFormData((prev) => ({ ...prev, logo: `uploads/logo.png` }));

  // Annuler les modifications
  const handleCancel = () => setFormData({ ...organization });

  // Soumettre les données
  const handleSubmit = (e) => {
    e.preventDefault();
    updateOrganization(formData);
  };

  const inputFields = [
    {
      label: t("name"),
      name: "name",
      type: "text",
      placeholder: "My Organization",
      required: true,
    },
    {
      label: t("email"),
      name: "email",
      type: "text",
      placeholder: "myorganisation@example.com",
      required: true,
    },
    {
      label: t("primary_color"),
      name: "primary_color",
      type: "color",
      required: true,
    },
    {
      label: t("secondary_color"),
      name: "secondary_color",
      type: "color",
      required: true,
    },
    {
      label: t("background_color"),
      name: "background_color",
      type: "color",
      required: true,
    },
    {
      label: t("website"),
      name: "website",
      type: "text",
      placeholder: "https://www.example.com",
    },
    {
      label: "Twitch",
      name: "twitch",
      type: "text",
      placeholder: "Twitch Login (ex:anticesc)",
    },
    {
      label: "Twitter",
      name: "twitter",
      type: "text",
      placeholder: "Twitter Login (ex:anticesc)",
    },
    {
      label: "Instagram",
      name: "instagram",
      type: "text",
      placeholder: "Instagram Login (ex:anticesc)",
    },
    {
      label: "Facebook",
      name: "facebook",
      type: "text",
      placeholder: "Facebook Login (ex:anticesc)",
    },
  ];

  return (
    <div className="container-form-settings flex flex-col m-4 min-[1820px]:my-4 min-[1820px]:mx-auto p-6 surface-a10 rounded max-w-screen-2xl">
      <div className="w-full px-3 sm:w-1/2">
        <OrganizationLogoUpload
          onChange={handleLogoChange}
          linkImage={organization.logo}
        />
      </div>
      <form onSubmit={handleSubmit} className="mx-auto w-full">
        <div className="-mx-3 flex flex-wrap">
          {inputFields.map((field, index) => (
            <InputField
              key={index}
              label={field.label}
              name={field.name}
              type={field.type}
              value={formData[field.name] || ""}
              onChange={handleChange}
              required={field.required}
              placeholder={field.placeholder}
            />
          ))}
        </div>
        <div className="flex space-x-4">
          <button type="submit" className="submit px-5 py-1.5">
            {t("save_changes")}
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="cancel-button button-form px-5 py-1.5"
          >
            {t("cancel")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default OrganizationSettings;
