import React, { useContext, useState } from "react";
import { Icon } from "@iconify/react";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { useTranslation } from "react-i18next";

const UserProfileConnections = () => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(UserContext);

  const [gameUsernames, setGameUsernames] = useState({
    leagueoflegends_login: user.leagueoflegends_login || [],
    valorant_login: user.valorant_login || [],
    fortnite_login: user.fortnite_login || [],
  });

  const [newUsername, setNewUsername] = useState({
    leagueoflegends_login: "",
    valorant_login: "",
    fortnite_login: "",
  });

  const [isInputVisible, setIsInputVisible] = useState({
    leagueoflegends_login: false,
    valorant_login: false,
    fortnite_login: false,
  });

  const socialPlatforms = [
    {
      type: "social",
      name: "twitter",
      icon: "simple-icons:x",
      username: user.twitter_login,
    },
    {
      type: "social",
      name: "twitch",
      icon: "simple-icons:twitch",
      username: user.twitch_login,
    },
    {
      type: "social",
      name: "discord",
      icon: "simple-icons:discord",
      username: user.discord_tag,
    },
  ];

  const gamePlatforms = [
    {
      name: "leagueoflegends",
      icon: "simple-icons:leagueoflegends",
      loginField: "leagueoflegends_login",
    },
    {
      name: "valorant",
      icon: "simple-icons:valorant",
      loginField: "valorant_login",
    },
    {
      name: "fortnite",
      icon: "tabler:brand-fortnite",
      loginField: "fortnite_login",
    },
  ];

  const handleAddUsername = async (game) => {
    const updatedUsernames = [...gameUsernames[game], newUsername[game]];

    try {
      await axios.put(
        `https://vps-e65866ce.vps.ovh.net:5000/api/user/${user._id}`,
        { [game]: updatedUsernames },
        { withCredentials: true }
      );

      setGameUsernames((prev) => ({
        ...prev,
        [game]: updatedUsernames,
      }));
      setUser((prevUser) => ({
        ...prevUser,
        [game]: updatedUsernames,
      }));

      setNewUsername((prev) => ({ ...prev, [game]: "" }));
    } catch (error) {
      console.error(`Erreur lors de la mise à jour de ${game}:`, error);
      alert(t("profile_update_error", { platform: game }));
    }
  };

  const handleRemoveUsername = async (game, usernameToRemove) => {
    const updatedUsernames = gameUsernames[game].filter(
      (username) => username !== usernameToRemove
    );

    try {
      await axios.put(
        `https://vps-e65866ce.vps.ovh.net:5000/api/user/${user._id}`,
        { [game]: updatedUsernames },
        { withCredentials: true }
      );

      setGameUsernames((prev) => ({
        ...prev,
        [game]: updatedUsernames,
      }));
      setUser((prevUser) => ({
        ...prevUser,
        [game]: updatedUsernames,
      }));
    } catch (error) {
      console.error(`Erreur lors de la mise à jour de ${game}:`, error);
      alert(t("profile_update_error", { platform: game }));
    }
  };

  const handleToggleInput = (game) => {
    setIsInputVisible((prev) => ({
      ...prev,
      [game]: !prev[game],
    }));
  };

  const handleConnect = (platform) => {
    window.location.href = `https://vps-e65866ce.vps.ovh.net:5000/auth/${platform}`;
  };

  const handleDisconnect = async (platform) => {
    const confirmDelete = window.confirm(
      `Êtes-vous sûr de vouloir vous déconnecter de ${platform} ?`
    );
    if (!confirmDelete) return;

    try {
      await axios.put(
        `https://vps-e65866ce.vps.ovh.net:5000/api/user/${user._id}`,
        {
          [`${platform}_login`]: null,
        },
        { withCredentials: true }
      );
      setUser((prevUser) => ({
        ...prevUser,
        [`${platform}_login`]: null,
      }));
      alert(t("profile_updated_success"));
    } catch (error) {
      console.error(`Erreur lors de la déconnexion de ${platform}:`, error);
      alert(t("profile_update_error", { platform }));
    }
  };

  const renderGamePlatform = ({ name, icon, loginField }) => (
    <div key={loginField} className="flex flex-col mb-4 p-2 shadow-sm rounded">
      <div className="flex items-center justify-between">
        <Icon icon={icon} className="w-10 h-10 mr-4" />
        <div className="flex-grow">
          <span>{t(name)}</span>
          {gameUsernames[loginField].length === 0 ? (
            <p className="text-sm text-gray-500">{t("not_connected")}</p>
          ) : (
            <div className="flex flex-wrap mt-1">
              {gameUsernames[loginField].map((username) => (
                <div
                  key={username}
                  className="flex items-center justify-between p-1 bg-neutral-800 rounded mb-1 ml-1 text-sm "
                >
                  <span>{username}</span>
                  <button
                    onClick={() => handleRemoveUsername(loginField, username)}
                    className="color-primary ml-1"
                  >
                    <Icon icon="mdi:close" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
        <button
          onClick={() => handleToggleInput(loginField)}
          className="p-2 rounded-lg text-white button-form"
        >
          <Icon icon="mdi:pencil-outline" />
        </button>
      </div>

      {isInputVisible[loginField] && (
        <div className="mt-4">
          <h3 className="text-sm font-semibold mb-2">
            {t("add_username_for")} {name}
          </h3>
          <div className="flex items-center gap-2">
            <input
              type="text"
              value={newUsername[loginField]}
              onChange={(e) =>
                setNewUsername((prev) => ({
                  ...prev,
                  [loginField]: e.target.value,
                }))
              }
              placeholder={t("enter_username")}
              className="input-field"
            />
            <button
              onClick={() => handleAddUsername(loginField)}
              className="submit px-5 py-1.5"
              disabled={!newUsername[loginField]}
            >
              {t("submit")}
            </button>
          </div>
        </div>
      )}
    </div>
  );

  const renderSocialPlatform = ({ name, icon, username }) => {
    const isConnected = Boolean(username);
    const buttonClass = isConnected ? "button-form-delete" : "button-form";
    const iconType = isConnected ? "mdi:trash-can-outline" : "mdi:link-variant";
    const action = isConnected
      ? () => handleDisconnect(name)
      : () => handleConnect(name);

    return (
      <div
        key={name}
        className="flex items-center justify-between p-2 mb-1 shadow-sm"
      >
        <Icon icon={icon} className="w-10 h-10 mr-4" />
        <div className="flex-grow">
          <span>{t(name)}</span>
          <p
            className={`text-sm ${
              username ? "color-primary" : "text-gray-500"
            }`}
          >
            {username || t("not_connected")}
          </p>
        </div>
        {name !== "discord" && (
        <button
          onClick={action}
          className={`p-2 rounded-lg text-white ${buttonClass}`}
        >
          <Icon icon={iconType} />
        </button>
        )}
      </div>
    );
  };

  return (
    <div className="container-form-settings flex flex-col md:flex-row m-4 min-[1820px]:my-4 min-[1820px]:mx-auto p-6 surface-a10 rounded max-w-screen-2xl gap-8">
      <div className="w-full md:w-1/2">
        <h2 className="text-lg font-bold">{t("games_account")}</h2>
        <p className="text-sm text-gray-400 mb-4">{t("games_account_desc")}</p>
        {gamePlatforms.map(renderGamePlatform)}
      </div>
      <div className="w-full md:w-1/2">
        <h2 className="text-lg font-bold">{t("social_account")}</h2>
        <p className="text-sm text-gray-400 mb-4">{t("social_account_desc")}</p>
        {socialPlatforms.map(renderSocialPlatform)}
      </div>
    </div>
  );
};

export default UserProfileConnections;