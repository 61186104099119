import React, { createContext, useState, useEffect } from "react";

export const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
  const [organization, setOrganization] = useState([]);
  const [error, setError] = useState(null); // État pour gérer les erreurs

  const setCSSVariables = (data) => {
    const { primary_color, secondary_color, background_color } = data;
    document.documentElement.style.setProperty(
      "--primary-color",
      primary_color
    );
    document.documentElement.style.setProperty(
      "--secondary-color",
      secondary_color
    );
    document.documentElement.style.setProperty(
      "--background-color",
      background_color
    );
  };

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await fetch("https://vps-e65866ce.vps.ovh.net:5000/api/organization", {
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          setOrganization(data);
          setCSSVariables(data);
        } else {
          throw new Error(
            `Erreur lors de la récupération de l'organisation: ${response.status}`
          );
        }
      } catch (err) {
        setError(err.message); // Stockez l'erreur
        console.error("Failed to fetch organization:", err);
      }
    };

    fetchOrganization();
  }, []);

  const updateOrganization = async (newData) => {
    try {
      const response = await fetch("https://vps-e65866ce.vps.ovh.net:5000/api/organization", {
        method: "PUT", // ou "PATCH" selon votre API
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
      });

      if (response.ok) {
        const updatedData = await response.json();
        setOrganization(updatedData);
        setCSSVariables(updatedData); // Mettre à jour les variables CSS
      } else {
        throw new Error(
          `Erreur lors de la mise à jour de l'organisation: ${response.status}`
        );
      }
    } catch (error) {
      setError(error.message);
      console.error("Failed to update organization:", error);
    }
  };

  return (
    <OrganizationContext.Provider
      value={{ organization, updateOrganization, error }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
