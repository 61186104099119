import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Anchor = ({ titlePage }) => {
  const { t } = useTranslation();
  return (
    <div className="anchor flex flex-col md:flex-row justify-between items-center m-4 min-[1820px]:my-4 min-[1820px]:mx-auto p-4 surface-a10 rounded max-w-screen-2xl">
      <span className="font-bold">{titlePage}</span>
      <nav className="flex items-center mt-2 md:mt-0">
        <Link to="/dashboard" className="text-xs main-link-anchor">
          {t("dashboard")}
        </Link>
        <span className="mx-2"> - </span>
        <span className="text-xs secondary-link-anchor p-1 rounded">
          {titlePage}
        </span>
      </nav>
    </div>
  );
};

export default Anchor;
