import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Anchor from "../utilities/Anchor";
import { Icon } from "@iconify/react"; // Assurez-vous d'importer Iconify

const InputField = ({
  label,
  name,
  type = "text",
  placeholder,
  value,
  onChange,
  required,
}) => (
  <div className="w-full px-3 sm:w-1/2">
    <div className="mb-5">
      <label htmlFor={name} className="block text-xs font-medium px-0.5 py-1">
        {label}
      </label>
      <input
        type={type}
        name={name}
        className="w-full"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
      />
    </div>
  </div>
);

const UserUpdate = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    pseudo: "",
    birthday: "",
    email: "",
    address: "",
    zip_code: "",
    town: "",
    country: "",
    role: "",
    team: "",
  });
  const [roles, setRoles] = useState([]);
  const [gameUsernames, setGameUsernames] = useState({
    leagueoflegends_login: [],
    valorant_login: [],
    fortnite_login: [],
  });
  const [newUsername, setNewUsername] = useState({
    leagueoflegends_login: "",
    valorant_login: "",
    fortnite_login: "",
  });

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`https://vps-e65866ce.vps.ovh.net:5000/api/users/${id}`, { withCredentials: true });
        setUser(response.data);
        setFormData(initialFormData(response.data));
        setGameUsernames({
          leagueoflegends_login: response.data.leagueoflegends_login || [],
          valorant_login: response.data.valorant_login || [],
          fortnite_login: response.data.fortnite_login || [],
        });
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'utilisateur :",
          error
        );
        alert("Erreur lors de la récupération des informations utilisateur.");
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await axios.get("https://vps-e65866ce.vps.ovh.net:5000/api/roles", { withCredentials: true });
        const rolesArray = Object.entries(response.data.roles).map(
          ([key, value]) => ({
            key,
            name: value.name,
          })
        );
        setRoles(rolesArray);
      } catch (error) {
        console.error("Erreur lors de la récupération des rôles :", error);
        alert("Erreur lors de la récupération des rôles.");
      }
    };

    fetchUser();
    fetchRoles();
  }, [id]);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const initialFormData = (userData) => ({
    name: userData?.name || "",
    surname: userData?.surname || "",
    pseudo: userData?.pseudo || "",
    birthday: formatDate(userData?.birthday) || "",
    email: userData?.email || "",
    address: userData?.address || "",
    zip_code: userData?.zip_code || "",
    town: userData?.town || "",
    country: userData?.country || "",
    role: userData?.role || "",
    team: userData?.team || "",
  });

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleUsernameChange = (game, value) => {
    const key = `${game}_login`;
    setNewUsername((prev) => ({ ...prev, [key]: value }));
  };

  const handleAddUsername = (game) => {
    const key = `${game}_login`;
    if (newUsername[key]) {
      setGameUsernames((prev) => {
        const currentUsernames = prev[key] || [];
        return {
          ...prev,
          [key]: [...currentUsernames, newUsername[key]],
        };
      });
      setNewUsername((prev) => ({ ...prev, [key]: "" }));
    }
  };

  const handleRemoveUsername = (game, username) => {
    const key = `${game}_login`;
    setGameUsernames((prev) => {
      const currentUsernames = prev[key] || [];
      return {
        ...prev,
        [key]: currentUsernames.filter((name) => name !== username),
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const updatedFormData = {
      ...formData,
      leagueoflegends_login: gameUsernames.leagueoflegends_login,
      valorant_login: gameUsernames.valorant_login,
      fortnite_login: gameUsernames.fortnite_login,
    };
  
    try {
      await axios.put(
        `https://vps-e65866ce.vps.ovh.net:5000/api/users/${user._id}`,
        updatedFormData,
        { withCredentials: true }
      );
  
      setUser((prev) => ({ ...prev, ...updatedFormData }));
      alert("Profil mis à jour avec succès !");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du profil :", error);
      alert("Échec de la mise à jour du profil.");
    }
  };

  const handleCancel = () => {
    setFormData(initialFormData(user));
  };

  const formFields = [
    { label: t("name"), name: "name", placeholder: "John", required: true },
    {
      label: t("surname"),
      name: "surname",
      placeholder: "Doe",
      required: true,
    },
    {
      label: t("username"),
      name: "pseudo",
      placeholder: "JohnDoe",
      required: true,
    },
    { label: t("birthday"), name: "birthday", type: "date", required: true },
    {
      label: t("email"),
      name: "email",
      type: "email",
      placeholder: "johndoe@example.com",
      required: true,
    },
    { label: t("address"), name: "address", placeholder: "Address" },
    {
      label: t("zip_code"),
      name: "zip_code",
      placeholder: "34000",
    },
    { label: t("town"), name: "town", placeholder: "Montpellier" },
    {
      label: t("country"),
      name: "country",
      placeholder: "France",
    },
    { label: t("team"), name: "team", placeholder: "team name" },
  ];

  if (!user) {
    return <div>Chargement...</div>;
  }

  return (
    <div>
      <Anchor titlePage={t("modify_profile_of") + " " + user.pseudo} />
      <div className="container-form-settings flex flex-col md:flex-row justify-between items-center m-4 min-[1820px]:my-4 min-[1820px]:mx-auto p-6 surface-a10 rounded max-w-screen-2xl">
        <div className="mx-auto w-full">
          <h3 className="text-lg font-bold">{t("user_infos")}</h3>
          <form onSubmit={handleSubmit}>
            <div className="-mx-3 flex flex-wrap">
              {formFields.map(
                ({ label, name, type, placeholder, required }) => (
                  <InputField
                    key={name}
                    label={label}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    value={formData[name]}
                    onChange={handleChange}
                    required={required}
                  />
                )
              )}
              <div className="w-full px-3 sm:w-1/2">
                <h3 className="text-lg font-bold">{t("user_rights")}</h3>
                <div className="mb-3">
                  <label
                    htmlFor="role"
                    className="block text-xs font-medium px-0.5 py-1"
                  >
                    {t("role")}
                  </label>
                  <select
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                    required
                  >
                    {/* Affiche d'abord le rôle actuel */}
                    {roles
                          .filter(({ key }) => key === formData.role)
                          .map(({ key, name }) => (
                            <option key={key} value={key}>
                              {name}
                            </option>
                          ))}

                        {/* Affiche les autres rôles */}
                        {roles
                          .filter(({ key }) => key !== formData.role)
                          .map(({ key, name }) => (
                            <option key={key} value={key}>
                              {name}
                            </option>
                          ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <h3 className="text-lg font-bold">{t("user_games")}</h3>
              <div className="mt-2 flex flex-wrap">
                {["leagueoflegends", "valorant", "fortnite"].map((game) => (
                  <div
                    key={game}
                    className="w-full sm:w-1/3 mb-4 flex items-start"
                  >
                    <div className="flex-shrink-0 mr-4">
                      <Icon
                        icon={
                          game === "fortnite"
                            ? "tabler:brand-" + game
                            : "simple-icons:" + game
                        }
                        className="text-4xl"
                      />
                    </div>
                    <div className="flex-grow">
                      <h4 className="text-lg font-semibold">{t(game)}</h4>
                      <ul className="list-disc mb-2 flex flex-wrap">
                        {" "}
                        {/* Utilisation de flex-wrap pour les <li> */}
                        {gameUsernames[`${game}_login`].map((username) => (
                          <li
                            key={username}
                            className="flex items-center justify-between p-1 bg-neutral-800 rounded mb-1 mr-2 text-sm" // Ajout de mr-2 pour l'espacement entre les <li>
                          >
                            <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                              {username}
                            </span>
                            <button
                              type="button"
                              onClick={() =>
                                handleRemoveUsername(game, username)
                              }
                              className="color-primary"
                            >
                              <Icon icon="mdi:close" />
                            </button>
                          </li>
                        ))}
                      </ul>
                      <input
                        type="text"
                        value={newUsername[`${game}_login`]}
                        onChange={(e) =>
                          handleUsernameChange(game, e.target.value)
                        }
                        placeholder={t(`add_${game}_username`)}
                        className="p-2 rounded w-auto" // L'input occupera toute la largeur disponible
                      />
                      <button
                        type="button"
                        onClick={() => handleAddUsername(game)}
                        className="submit ml-3 mt-2 px-5 py-1.5"
                      >
                        {t("add")}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex space-x-4 mt-5">
              <button type="submit" className="submit px-5 py-1.5">
                {t("save_changes")}
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="cancel-button button-form px-5 py-1.5"
              >
                {t("cancel")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserUpdate;
