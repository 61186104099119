import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import InputField from "../utilities/InputField";

const initialFormData = {
  message: "",
  condition: "",
  type: "",
  statut: "",
};

const options = {
  type: [
    { value: "success", label: "success" },
    { value: "warn", label: "warn" },
    { value: "infos", label: "infos" },
  ],
  statut: [
    { value: "active", label: "active" },
    { value: "inactive", label: "inactive" },
  ],
};

const OrganizationAlertsUpdate = ({ alert, onClose, fetchAlerts }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = ({ target: { name, value } }) =>
    setFormData((prevData) => ({ ...prevData, [name]: value }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `https://vps-e65866ce.vps.ovh.net:5000/api/alerts/${alert._id}`,
        formData
      );
      fetchAlerts();
      onClose();
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'alerte :", error);
    }
  };

  const handleCancel = () => setFormData(alert || initialFormData);

  useEffect(() => {
    if (alert) setFormData({ ...initialFormData, ...alert });
  }, [alert]);

  return (
    <div className="mx-auto w-full">
      <form onSubmit={handleSubmit} className="flex flex-col">
        <div className="-mx-3 flex flex-wrap">
          {["type", "statut"].map((field) => (
            <InputField
              key={field}
              label={t(field)}
              name={field}
              type="select"
              value={formData[field]}
              onChange={handleChange}
              required
              options={options[field].map((option) => ({
                value: option.value,
                label: t(option.label),
              }))}
              placeholder={t(`select_all_${field}`)}
            />
          ))}
        </div>

        {["condition", "message"].map((field) => (
          <InputField
            key={field}
            label={t(field)}
            name={field}
            type={field === "message" ? "textarea" : "text"}
            value={formData[field]}
            onChange={handleChange}
            required
            placeholder={field === "condition" ? "!user.name" : ""}
            rows={field === "message" ? 4 : undefined} // Only applies to textarea
          />
        ))}

        <div className="flex space-x-4">
          <button type="submit" className="submit px-5 py-1.5">
            {t("save_changes")}
          </button>
          <button
            type="button"
            className="cancel-button button-form px-5 py-1.5"
            onClick={handleCancel}
          >
            {t("cancel")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default OrganizationAlertsUpdate;
