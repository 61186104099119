import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Anchor from "../utilities/Anchor.js";
import OrganizationSettings from "./OrganizationSettings";
import OrganizationAlerts from "./OrganizationAlerts";
import OrganizationTeams from "./OrganizationTeams.js";
import Tabs from "../utilities/Tabs";

const Organization = () => {
  const [activeTab, setActiveTab] = useState("organization");
  const { t } = useTranslation();

  const tabs = [
    {
      key: "organization",
      icon: "mdi:sitemap",
      label: t("organization_settings"),
      component: <OrganizationSettings />,
    },
    {
      key: "alerts",
      icon: "mdi:bell",
      label: t("alerts_settings"),
      component: <OrganizationAlerts />,
    },
    {
      key: "teams",
      icon: "mdi:users-group",
      label: t("teams_settings"),
      component: <OrganizationTeams />,
    },
  ];

  const activeTabContent = tabs.find((tab) => tab.key === activeTab);

  return (
    <div>
      <Anchor titlePage={activeTabContent.label} />
      <div className="profile-settings-menu flex flex-col md:flex-row justify-between items-center m-4 min-[1820px]:my-4 min-[1820px]:mx-auto rounded max-w-screen-2xl">
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div>{activeTabContent.component}</div>
    </div>
  );
};

export default Organization;
