import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react/dist/iconify.js";
import InputField from "../utilities/InputField";

const initialFormData = {
  name: "",
  type: "",
  icon: "",
  game: "",
  managers: [],
};

const OrganizationTeamsCreate = ({ onClose, fetchTeams }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("https://vps-e65866ce.vps.ovh.net:5000/api/users", {
          withCredentials: true,
        });
        setUsers(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des utilisateurs :",
          error
        );
      }
    };
    fetchUsers();
  }, []);

  const handleChange = ({ target: { name, value } }) =>
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === "type" && value !== "esport" ? { game: "" } : {}),
    }));

  const handleAddManager = () =>
    setFormData((prevData) => ({
      ...prevData,
      managers: [...prevData.managers, ""],
    }));

  const handleRemoveManager = (index) =>
    setFormData((prevData) => ({
      ...prevData,
      managers: prevData.managers.filter((_, i) => i !== index),
    }));

  const handleManagerChange = (index, value) =>
    setFormData((prevData) => ({
      ...prevData,
      managers: prevData.managers.map((manager, i) =>
        i === index ? value : manager
      ),
    }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://vps-e65866ce.vps.ovh.net:5000/api/teams", formData);
      fetchTeams();
      onClose();
    } catch (error) {
      console.error("Erreur lors de la création de l'équipe :", error);
    }
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    onClose();
  };

  const ManagerSelect = ({ manager, index }) => (
    <div className="flex items-center space-x-2">
      <select
        value={manager}
        onChange={(e) => handleManagerChange(index, e.target.value)}
        className="p-2 rounded"
        required
      >
        <option value="">{t("select_manager")}</option>
        {users.map((user) => (
          <option key={user._id} value={user._id}>
            {user.pseudo}
          </option>
        ))}
      </select>
      <button
        type="button"
        onClick={() => handleRemoveManager(index)}
        className="color-primary !ml-1 !mr-2"
      >
        <Icon icon="mdi:close" />
      </button>
    </div>
  );

  const selectOptions = {
    type: [
      { value: "transversal", label: t("transversal") },
      { value: "esport", label: t("esport") },
    ],
    game: [
      { value: "leagueoflegends", label: "League of Legends" },
      { value: "valorant", label: "Valorant" },
      { value: "fortnite", label: "Fortnite" },
    ],
  };

  return (
    <div className="mx-auto w-full">
      <form onSubmit={handleSubmit} className="flex flex-col">
        <InputField
          label={t("team_name")}
          name="name"
          type="text"
          placeholder="Enter team name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <InputField
          label={t("icon")}
          name="icon"
          type="text"
          placeholder="Enter a icon"
          value={formData.icon}
          onChange={handleChange}
          required
        />
        <InputField
          label={t("type")}
          name="type"
          type="select"
          value={formData.type}
          onChange={handleChange}
          required
          options={[
            { value: "", label: t("select_type") },
            ...selectOptions.type.map((option) => ({
              value: option.value,
              label: option.label,
            })),
          ]}
        />

        {formData.type === "esport" && (
          <InputField
            label={t("game")}
            name="game"
            type="select"
            value={formData.game}
            onChange={handleChange}
            required
            options={[
              { value: "", label: t("select_game") },
              ...selectOptions.game.map((option) => ({
                value: option.value,
                label: option.label,
              })),
            ]}
          />
        )}

        <div className="flex flex-col space-y-2 mb-5">
          <label
            htmlFor="managers"
            className="block text-xs font-medium px-0.5 py-1"
          >
            {t("managers")}
          </label>
          <div className="flex flex-wrap mt-1">
            {formData.managers.map((manager, index) => (
              <ManagerSelect key={index} manager={manager} index={index} />
            ))}
          </div>
          <button
            type="button"
            onClick={handleAddManager}
            className="color-primary hover:underline"
          >
            {t("add_manager")}
          </button>
        </div>

        <div className="flex space-x-4">
          <button type="submit" className="submit px-5 py-1.5">
            {t("create_team")}
          </button>
          <button
            type="button"
            className="cancel-button button-form px-5 py-1.5"
            onClick={handleCancel}
          >
            {t("cancel")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default OrganizationTeamsCreate;
