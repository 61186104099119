import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { OrganizationContext } from "../context/OrganizationContext";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Alerts from "../components/Alerts";

const Dashboard = () => {
  const [isMenuCollapsed, setMenuCollapsed] = useState(false);
  const [isMenuResponsive, setMenuResponsive] = useState(false);
  const { user } = useContext(UserContext);
  const { organization } = useContext(OrganizationContext);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      // Par exemple, 768px pour une résolution mobile
      setMenuResponsive(true);
      setMenuCollapsed(true); // Réduire le menu
    } else {
      setMenuResponsive(false); // Agrandir le menu
    }
  };

  const handleMenuToggle = () => {
    setMenuCollapsed(!isMenuCollapsed);
  };

  useEffect(() => {
    // Appeler handleResize initialement
    handleResize();

    // Ajouter un listener pour les redimensionnements
    window.addEventListener("resize", handleResize);

    // Nettoyage : retirer le listener lors du démontage du composant
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <Sidebar
        onMenuToggle={handleMenuToggle}
        isMenuCollapsed={isMenuCollapsed}
        isMenuResponsive={isMenuResponsive}
        organization={organization}
      />

      {/* Content Area */}
      <div className="flex-1 flex flex-col">
        {/* Top Navigation Bar */}
        <Navbar
          onMenuToggle={handleMenuToggle}
          isMenuCollapsed={isMenuCollapsed}
          user={user}
        />

        {/* Main Content */}
        <main className="flex-grow p-4">
          <Alerts user={user} organization={organization} />
          <h2 className="text-xl font-semibold">Coming soon...</h2>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
