import React, { useContext } from "react";
import { Icon } from "@iconify/react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/UserContext";

const Sidebar = ({
  onMenuToggle,
  isMenuCollapsed,
  isMenuResponsive,
  organization,
}) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const location = useLocation();

  const renderMenuItem = (to, icon, label) => (
    <Link to={to}>
      <li
        className={`flex items-center mx-2 py-2 px-4 cursor-pointer ${
          location.pathname === to ? "active" : ""
        }`}
      >
        <span>
          <Icon icon={icon} height="24" width="24" />
        </span>
        <span className={`${isMenuCollapsed ? "hidden" : "ml-4 text-sm"}`}>
          {label}
        </span>
      </li>
    </Link>
  );

  return (
    <div
      className={`${
        isMenuCollapsed
          ? isMenuResponsive
            ? "hidden"
            : "w-18 surface-a10 text-white flex flex-col transition-all duration-300 sidebar"
          : isMenuResponsive
          ? "fixed inset-0 bg-black bg-opacity-50 flex justify-center z-50 w-64 surface-a10 text-white flex flex-col transition-all duration-300 sidebar"
          : "w-64 surface-a10 text-white flex flex-col transition-all duration-300 sidebar"
      }`}
    >
      <div className="flex p-4 items-center h-16">
        <div className="flex items-center">
          <img
            src={`https://vps-e65866ce.vps.ovh.net:5000/${organization.logo}`}
            alt="Logo"
            className={`${isMenuCollapsed ? "" : "mr-2"} h-10`}
          />
          <h1
            className={`${
              isMenuCollapsed ? "hidden" : "block"
            } text-lg font-bold`}
          >
            {organization.name}
          </h1>
        </div>
        {isMenuResponsive && (
          <button
            onClick={onMenuToggle}
            className="px-10 py-2 text-gray-400 hover:text-gray-300"
          >
            <Icon icon="mdi:remove-bold" width="24" height="24" />
          </button>
        )}
      </div>
      <nav className="flex-grow">
        <span
          className={`${
            isMenuCollapsed
              ? "hidden"
              : "ml-4 caption-text font-secondary primary-a50"
          }`}
        >
          {t("dashboard_title")}
        </span>
        <ul>{renderMenuItem("/dashboard", "mdi:laptop", t("dashboard"))}</ul>
        {user.role === "admin" && (
          <>
            <span
              className={`${
                isMenuCollapsed
                  ? "hidden"
                  : "ml-4 caption-text font-secondary primary-a50"
              }`}
            >
              {t("administration_title")}
            </span>
            <ul>{renderMenuItem("/users/list", "mdi:users", t("users"))}</ul>
          </>
        )}
        <span
          className={`${
            isMenuCollapsed
              ? "hidden"
              : "ml-4 caption-text font-secondary primary-a50"
          }`}
        >
          {t("settings")}
        </span>
        <ul>
          {renderMenuItem(
            "/settings/profile",
            "mdi:card-account-details-outline",
            t("account_settings")
          )}
          {user.role === "admin" &&
            renderMenuItem(
              "/settings/organization",
              "mdi:sitemap",
              t("organization_settings")
            )}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
