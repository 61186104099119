import React, { useState } from "react";
import UserProfileUpdate from "./UserProfileUpdate";
import UserProfileConnections from "./UserProfileConnections";
import { useTranslation } from "react-i18next";
import Anchor from "../utilities/Anchor.js";
import Tabs from "../utilities/Tabs";

const Profile = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("account");

  const tabs = [
    {
      key: "account",
      icon: "mdi:user",
      label: t("account_settings"),
      component: <UserProfileUpdate />,
    },
    {
      key: "connections",
      icon: "mdi:link-variant",
      label: t("connections_settings"),
      component: <UserProfileConnections />,
    },
  ];

  const activeTabContent = tabs.find((tab) => tab.key === activeTab);

  return (
    <div>
      <Anchor titlePage={activeTabContent?.label} />
      <div className="profile-settings-menu flex flex-col md:flex-row justify-between items-center m-4 min-[1820px]:my-4 min-[1820px]:mx-auto rounded max-w-screen-2xl">
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div>{activeTabContent?.component}</div>
    </div>
  );
};

export default Profile;
