import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Banner from "../utilities/Banner";
import { useTranslation } from "react-i18next";
import UserProfile from "./UserProfile";
import Tabs from "../utilities/Tabs";
import UserGames from "./UserGames";

const User = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("account");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`https://vps-e65866ce.vps.ovh.net:5000/api/users/${id}`, { withCredentials: true });
        setUser(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'utilisateur :",
          error
        );
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  }, [id]);

  if (loading) return <div>Chargement...</div>;

  const tabs = [
    {
      key: "account",
      icon: "mdi:user",
      label: t("profile"),
      component: <UserProfile user={user} />,
    },
  ];

  if (user.leagueoflegends_login.length > 0) {
    tabs.push({
      key: "leagueoflegends",
      icon: "simple-icons:leagueoflegends",
      label: t("leagueoflegends"),
      component: <UserGames user={user} />,
    });
  }

  if (user.valorant_login.length > 0) {
    tabs.push({
      key: "valorant",
      icon: "simple-icons:valorant",
      label: t("valorant"),
      component: <UserGames user={user} />,
    });
  }

  if (user.fortnite_login.length > 0) {
    tabs.push({
      key: "fortnite",
      icon: "tabler:brand-fortnite",
      label: t("fortnite"),
      component: <UserGames user={user} />,
    });
  }

  const activeTabContent = tabs.find((tab) => tab.key === activeTab);

  return (
    <div className="overflow-auto">
      <Banner user={user} />
      <div className="profile-settings-menu flex flex-col md:flex-row justify-between items-center m-4 min-[1820px]:my-4 min-[1820px]:mx-auto rounded max-w-screen-2xl">
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div>{activeTabContent.component}</div>
    </div>
  );
};

export default User;