import React, { useState, useContext } from "react";
import { UserContext } from "../../context/UserContext"; // Assurez-vous que le chemin est correct
import axios from "axios";
import { useTranslation } from "react-i18next";

const InputField = ({
  label,
  name,
  type = "text",
  placeholder,
  value,
  onChange,
  required,
}) => (
  <div className="w-full px-3 sm:w-1/2">
    <div className="mb-5">
      <label htmlFor={name} className="block text-xs font-medium px-0.5 py-1">
        {label}
      </label>
      <input
        type={type}
        name={name}
        className="w-full"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
      />
    </div>
  </div>
);

const UserProfileUpdate = () => {
  const { user, setUser } = useContext(UserContext);
  const { t } = useTranslation();
  const initialFormData = () => ({
    name: user?.name || "",
    surname: user?.surname || "",
    pseudo: user?.pseudo || "",
    birthday: user?.birthday || "",
    email: user?.email || "",
    adress: user?.adress || "",
    zip_code: user?.zip_code || "",
    town: user?.town || "",
    country: user?.country || "",
  });

  const [formData, setFormData] = useState(initialFormData());

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `https://vps-e65866ce.vps.ovh.net:5000/api/user/${user._id}`, // Utiliser l'ID de l'utilisateur
        formData,
        { withCredentials: true }
      );

      setUser(response.data); // Mettre à jour le contexte avec les nouvelles informations
      alert("Profil mis à jour avec succès !");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du profil :", error);
      alert("Échec de la mise à jour du profil.");
    }
  };

  const handleCancel = () => {
    setFormData(initialFormData()); // Réinitialise le formulaire avec les données initiales
  };

  const formFields = [
    { label: "Name", name: "name", placeholder: "John", required: true },
    { label: "Surname", name: "surname", placeholder: "Doe", required: true },
    { label: "Pseudo", name: "pseudo", placeholder: "JohnDoe", required: true },
    { label: "Birthday", name: "birthday", type: "date", required: true },
    {
      label: "Email",
      name: "email",
      type: "email",
      placeholder: "johndoe@example.com",
      required: true,
    },
    { label: "Adress", name: "adress", placeholder: "Adress", required: true },
    {
      label: "Zip Code",
      name: "zip_code",
      placeholder: "34000",
      required: true,
    },
    { label: "Town", name: "town", placeholder: "Montpellier", required: true },
    {
      label: "Country",
      name: "country",
      placeholder: "France",
      required: true,
    },
  ];

  return (
    <div className="container-form-settings flex flex-col md:flex-row justify-between items-center m-4 min-[1820px]:my-4 min-[1820px]:mx-auto p-6 surface-a10 rounded max-w-screen-2xl">
      <div className="mx-auto w-full">
        <form onSubmit={handleSubmit}>
          <div className="-mx-3 flex flex-wrap">
            {formFields.map(({ label, name, type, placeholder, required }) => (
              <InputField
                key={name}
                label={label}
                name={name}
                type={type}
                placeholder={placeholder}
                value={formData[name]}
                onChange={handleChange}
                required={required}
              />
            ))}
          </div>
          <div className="flex space-x-4">
            <button type="submit" className="submit px-5 py-1.5">
            {t("save_changes")}
            </button>
            <button
              type="button"
              className="cancel-button button-form px-5 py-1.5"
              onClick={handleCancel}
            >
              {t("cancel")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserProfileUpdate;
