import React, { useContext } from "react";
import { OrganizationContext } from "../context/OrganizationContext";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";

const Home = () => {
  const { t } = useTranslation();
  const { organization, error } = useContext(OrganizationContext);

  const loginWithDiscord = () => {
    window.location.href = "https://vps-e65866ce.vps.ovh.net:5000/auth/discord";
  };

  if (error) {
    return <div>Erreur: {error}</div>; // Affichez l'erreur si elle existe
  }

  if (!organization) {
    return <div>Loading...</div>; // Affichez un message de chargement
  }

  return (
    <div className="home min-h-screen flex flex-col items-center justify-center px-4 overflow-hidden">
      {/* Logo and Site Name Section */}
      <div className="logo-section text-center md:mb-8">
        <img
          src={`https://vps-e65866ce.vps.ovh.net:5000/${organization.logo}`}
          alt="Site Logo"
          className="mx-auto h-24"
        />
        <h1 className="text-3xl font-bold mt-2">{organization.name}</h1>
      </div>

      <div className="login flex flex-col-reverse lg:flex-row w-full max-w-7xl gap-10 lg:gap-20">
        <div className="login-left flex flex-col w-full lg:w-1/2 mx-auto">
          <div className="login-left-container flex flex-col self-center gap-8">
            <div className="flex flex-row gap-4 items-start">
              <Icon
                className="icon"
                icon="icon-park-solid:handbag"
                width="48"
                height="48"
              />
              <div>
                <h6 className="font-bold">{t("home_title_1")}</h6>
                <p className="text-sm tracking-wide">{t("home_desc_1")}</p>
              </div>
            </div>
            <div className="flex flex-row gap-4 items-start">
              <Icon
                className="icon"
                icon="ri:team-fill"
                width="64"
                height="64"
              />
              <div>
                <h6 className="font-bold">{t("home_title_2")}</h6>
                <p className="text-sm tracking-wide">{t("home_desc_2")}</p>
              </div>
            </div>
            <div className="flex flex-row gap-4 items-start">
              <Icon
                className="icon"
                icon="fa6-solid:gifts"
                width="64"
                height="64"
              />
              <div>
                <h6 className="font-bold">{t("home_title_3")}</h6>
                <p className="text-sm tracking-wide">{t("home_desc_3")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="login-right flex flex-col self-center gap-4 p-8 rounded-lg w-full lg:w-1/2 mx-auto">
          <div className="text-2xl font-extrabold font-secondary text-center lg:text-left">
            {t("connect")}
          </div>
          <button
            className="text-center inline-flex items-center justify-center rounded-lg p-3 w-full"
            onClick={loginWithDiscord}
          >
            <span className="mr-2">
              <Icon icon="simple-icons:discord" width="30" height="30" />
            </span>
            {t("connect_discord")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
