import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import OrganizationAlertsUpdate from "./OrganizationAlertsUpdate";
import Modal from "../Modal";
import OrganizationAlertsCreate from "./OrganizationAlertsCreate";
import { useTranslation } from "react-i18next";

const OrganizationAlerts = () => {
  const { t } = useTranslation();
  const [alerts, setAlerts] = useState([]);
  const [filterType, setFilterType] = useState("all");
  const [isActive, setIsActive] = useState("all");
  const [modalState, setModalState] = useState({ isOpen: false, alert: null });

  const alertClasses = {
    warn: "bg-red-100 text-red-800 text-xs font-medium px-3 py-0.5 rounded dark:bg-red-900 dark:text-red-300",
    success:
      "bg-green-100 text-green-800 text-xs font-medium px-3 py-0.5 rounded dark:bg-green-900 dark:text-green-300",
    infos:
      "bg-blue-100 text-blue-800 text-xs font-medium px-3 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300",
    inactive:
      "bg-gray-100 text-gray-800 text-xs font-medium px-3 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300",
    active:
      "bg-green-100 text-green-800 text-xs font-medium px-3 py-0.5 rounded dark:bg-green-900 dark:text-green-300",
  };

  const fetchAlerts = useCallback(async () => {
    try {
      const response = await axios.get("https://vps-e65866ce.vps.ovh.net:5000/api/alerts", { withCredentials: true });
      setAlerts(response.data);
    } catch (error) {
      console.error("Error fetching alerts:", error);
    }
  }, []);

  useEffect(() => {
    fetchAlerts();
  }, [fetchAlerts]);

  const filteredAlerts = alerts.filter(
    (alert) =>
      (filterType === "all" || alert.type === filterType) &&
      (isActive === "all" || alert.statut === isActive)
  );

  const openModal = (alert = null) => setModalState({ isOpen: true, alert });
  const closeModal = () => setModalState({ isOpen: false, alert: null });

  const handleDelete = useCallback(async (id) => {
    try {
      await axios.delete(`https://vps-e65866ce.vps.ovh.net:5000/api/alerts/${id}`);
      setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert._id !== id));
    } catch (error) {
      console.error("Error deleting alert:", error);
    }
  }, []);

  const AlertActions = ({ alert }) => (
    <>
      <button
        onClick={() => openModal(alert)}
        className="m-1 text-gray-400 hover:text-gray-300"
      >
        <Icon icon="mdi:square-edit-outline" />
      </button>
      <button
        onClick={() => handleDelete(alert._id)}
        className="m-1 text-gray-400 hover:text-gray-300"
      >
        <Icon icon="mdi:remove-bold" />
      </button>
    </>
  );

  const renderOption = (value, label) => (
    <option value={value}>{t(label)}</option>
  );

  return (
    <div className="container-form-settings flex flex-col m-4 min-[1820px]:my-4 min-[1820px]:mx-auto surface-a10 rounded max-w-screen-2xl">
      <div className="flex flex-col p-6">
        <div className="flex justify-between items-center mb-4">
          <h5 className="font-bold mb-2">{t("filters")}</h5>
          <button
            onClick={() => openModal()}
            className="submit px-3 py-1.5 flex"
          >
            <Icon icon="mdi:plus" height="26" className="mr-2" />
            {t("add_alert")}
          </button>
        </div>
        <div className="flex flex-wrap w-full">
          <select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            className="w-full md:w-1/3 md:mr-4 mb-2"
          >
            {renderOption("all", "all_types")}
            {renderOption("success", "success")}
            {renderOption("warn", "warning")}
            {renderOption("infos", "infos")}
          </select>
          <select
            value={isActive}
            onChange={(e) => setIsActive(e.target.value)}
            className="w-full md:w-1/3 mb-2"
          >
            {renderOption("all", "all_statut")}
            {renderOption("active", "active")}
            {renderOption("inactive", "inactive")}
          </select>
        </div>
      </div>
      <table className="min-w-full">
        <thead>
          <tr>
            <th className="p-4">{t("message")}</th>
            <th className="p-4 hidden md:table-cell">{t("condition")}</th>
            <th className="p-4 hidden md:table-cell">{t("type")}</th>
            <th className="p-4 hidden md:table-cell">{t("status")}</th>
            <th className="p-4">{t("actions")}</th>
          </tr>
        </thead>
        <tbody>
          {filteredAlerts.map((alert) => (
            <tr key={alert._id}>
              <td className="px-4 py-2">{alert.message}</td>
              <td className="px-4 py-2 hidden md:table-cell">
                {alert.condition}
              </td>
              <td className="px-4 py-2 hidden md:table-cell">
                <span className={alertClasses[alert.type]}>{alert.type}</span>
              </td>
              <td className="px-4 py-2 hidden md:table-cell">
                <span className={alertClasses[alert.statut]}>
                  {alert.statut}
                </span>
              </td>
              <td className="px-4 py-2 text-xl text-center">
                <AlertActions alert={alert} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        title={modalState.alert ? t("modify_alert") : t("add_alert")}
        show={modalState.isOpen}
        onClose={closeModal}
      >
        {modalState.alert ? (
          <OrganizationAlertsUpdate
            alert={modalState.alert}
            onClose={closeModal}
            fetchAlerts={fetchAlerts}
          />
        ) : (
          <OrganizationAlertsCreate
            onClose={closeModal}
            fetchAlerts={fetchAlerts}
          />
        )}
      </Modal>
    </div>
  );
};

export default OrganizationAlerts;
