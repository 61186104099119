import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const initialFormData = {
  name: "",
  surname: "",
  role: "",
  team: "",
};

const UsersListView = ({ user, onClose, fetchUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || "N/A",
        surname: user.surname || "N/A",
        role: user.role || "N/A",
        team: user.team || "N/A",
      });
    }
  }, [user]);

  const handleViewProfile = () => {
    navigate(`/users/${user._id}`);
  };

  const handleModifyUser = () => {
    navigate(`/users/modify/${user._id}`);
  };

  const handleDeleteUser = async () => {
    const confirmDelete = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
    );
    if (!confirmDelete) return;

    try {
      await axios.delete(`https://vps-e65866ce.vps.ovh.net:5000/api/users/${user._id}`);
      fetchUsers(); // Recharge la liste des utilisateurs après suppression
      onClose(); // Ferme la vue actuelle
    } catch (error) {
      console.error("Erreur lors de la suppression de l'utilisateur", error);
    }
  };

  const getRandomColor = () => {
    const colors = [
      "bg-red-500",
      "bg-blue-500",
      "bg-green-500",
      "bg-yellow-500",
      "bg-purple-500",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <div className="mx-auto w-full">
      <table className="table-auto w-full border-collapse">
        <tbody>
          <tr className="border-b">
            <td className="px-1 py-2 text-sm font-medium text-white">
              {t("user")}:
            </td>
            <td className="px-4 py-2">
              <div className="flex items-center">
                {user.discord_avatar_url ? (
                  <img
                    src={user.discord_avatar_url}
                    alt={user.pseudo}
                    className="w-10 h-10 rounded-full mr-2"
                  />
                ) : (
                  <div
                    className={`w-10 h-10 rounded-full mr-2 flex items-center justify-center ${getRandomColor()}`}
                  >
                    <span className="text-white font-bold">
                      {user.pseudo.slice(0, 2).toUpperCase()}
                    </span>
                  </div>
                )}
                <div className="leading-tight">
                  <div className="font-bold text-sm">{user.pseudo}</div>
                  <div className="text-xs text-gray-400">{user.email}</div>
                </div>
              </div>
            </td>
          </tr>
          {Object.entries(formData).map(([key, value]) => (
            <tr key={key} className="border-b">
              <td className="px-1 py-2 text-sm font-medium text-white">
                {t(key)}:
              </td>
              <td className="px-4 py-2">
                <p>{value}</p>
              </td>
            </tr>
          ))}
          <tr className="border-b">
            <td className="px-1 py-2 text-sm font-medium text-white">
              {t("actions")}:
            </td>
            <td className="px-4 py-2">
              <button
                onClick={handleViewProfile}
                className="m-1 text-gray-400 hover:text-gray-300"
                aria-label="View Profile"
              >
                <Icon icon="mdi:eye-outline" width="26" height="26" />
              </button>
              <button
                onClick={handleModifyUser}
                className="m-1 text-gray-400 hover:text-gray-300"
                aria-label="Edit User"
              >
                <Icon icon="mdi:edit-box-outline" width="26" height="26" />
              </button>
              <button
                onClick={handleDeleteUser}
                className="m-1 text-gray-400 hover:text-gray-300"
                aria-label="Delete User"
              >
                <Icon icon="mdi:remove-bold" width="26" height="26" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default UsersListView;
