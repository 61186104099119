import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";

const Navbar = ({ onMenuToggle, isMenuCollapsed, user }) => {
  const { t, i18n } = useTranslation();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [language, setLanguage] = useState("fr"); // État pour la langue
  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => !prev);
  };

  const toggleLanguage = () => {
    const newLanguage = language === "fr" ? "en" : "fr";
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage); // Change la langue avec i18n
  };

  // Fermer le dropdown quand on clique en dehors
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false); // Ferme le dropdown si clic en dehors
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const flagSrc = require(`../assets/flags/icon-flag-${language}.svg`);

  return (
    <header className="h-16 flex-shrink-0 flex items-center justify-between px-4 surface-a10">
      <button onClick={onMenuToggle} className="menu-collapse">
        <span className="h-16">
          <Icon icon="mdi:menu" height="24" width="24" />
        </span>
      </button>

      <div className="relative flex items-center space-x-2" ref={dropdownRef}>
        <button
          onClick={toggleLanguage}
          className="focus:outline-none rounded-full"
        >
          <img
            src={flagSrc}
            alt={`Flag of ${language.toUpperCase()}`}
            className="w-6 h-6 mr-2 object-cover rounded-full"
          />
        </button>
        <button
          className="flex items-center space-x-2 focus:outline-none"
          onClick={handleDropdownToggle}
        >
          <img
            className="w-10 h-10 rounded-full"
            src={user.discord_avatar_url}
            alt="User Avatar"
          />
          <span>
            <Icon icon="mdi:chevron-down" width="24" height="24" />
          </span>
        </button>

        {/* Dropdown menu avec transition */}
        <div
          className={`dropdown-menu z-20 absolute right-0 p-2 mt-2 w-72 surface-a10 rounded shadow-lg top-full transition-all duration-300 ease-in-out 
  ${isDropdownOpen ? "opacity-100" : "opacity-0 pointer-events-none"}`}
        >
          <div className="flex items-center px-4 py-3">
            <img
              className="w-11 h-11 rounded-full mr-4"
              src={user.discord_avatar_url}
              alt="User Avatar"
            />
            <div className="flex-grow text-left leading-3">
              <h6 className="text-sm font-medium">
                {user.surname} {user.name}
              </h6>
              <span className="text-xs text-gray-500">{user.email}</span>
            </div>
          </div>
          <ul>
            <Link to={`/users/${user._id}`}>
              <li className="px-4 py-2 text-sm cursor-pointer">
                {t("my_profile")}
              </li>
            </Link>
            <Link to="/settings/profile">
              <li className="px-4 py-2 text-sm cursor-pointer">
                {t("account_settings")}
              </li>
            </Link>
              <li className="px-4 py-2 text-sm cursor-pointer">{t("logout")}</li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
