import { Icon } from "@iconify/react";
import React from "react";

const Modal = ({ show, onClose, title, children }) => {
  if (!show) return null;

  const handleBackgroundClick = (e) => {
    // Vérifie si le clic est sur l'arrière-plan (zone de superposition)
    if (e.target === e.currentTarget) {
      onClose(); // Ferme la modal
    }
  };

  return (
    <div
      onClick={handleBackgroundClick}
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 container-form-settings"
    >
      <div className="surface-a10 rounded p-4 rounded shadow-lg md:w-1/2">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold">{title}</h2>
          <button
            onClick={onClose}
            className="text-gray-400 text-2xl font-bold hover:text-gray-300"
          >
            <Icon icon="mdi:remove-bold" width="32" height="32" />
          </button>
        </div>
        <div className="mt-4">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
