import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { OrganizationContext } from "../context/OrganizationContext";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { Outlet } from "react-router-dom";

const Users = () => {
  const [isMenuCollapsed, setMenuCollapsed] = useState(false);
  const [isMenuResponsive, setMenuResponsive] = useState(false);
  const { user } = useContext(UserContext);
  const { organization } = useContext(OrganizationContext);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      // Par exemple, 768px pour une résolution mobile
      setMenuResponsive(true); // Réduire le menu
    } else {
      setMenuResponsive(false); // Agrandir le menu
    }
  };

  const handleMenuToggle = () => {
    setMenuCollapsed(!isMenuCollapsed);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <Sidebar
        onMenuToggle={handleMenuToggle}
        isMenuCollapsed={isMenuCollapsed}
        isMenuResponsive={isMenuResponsive}
        organization={organization}
      />

      {/* Content Area */}
      <div className="flex-1 flex flex-col">
        {/* Top Navigation Bar */}
        <Navbar
          onMenuToggle={handleMenuToggle}
          isMenuCollapsed={isMenuCollapsed}
          user={user}
        />
        <Outlet />
      </div>
    </div>
  );
};

export default Users;
