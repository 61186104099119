import React from "react";

const InputField = ({
  label,
  name,
  type = "text",
  placeholder,
  value,
  onChange,
  onClick,
  required,
  options,
  rows = 4,
}) => (
  <div className={`w-full px-3 ${type !== "textarea" ? "sm:w-1/2" : ""}`}>
    <div className="mb-5">
      {type !== "file" && (
        <label htmlFor={name} className="block text-xs font-medium px-0.5 py-1">
          {label}
        </label>
      )}
      {type === "select" && options ? (
        <select
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          className="w-full p-2 rounded"
        >
          <option value="">{placeholder}</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : type === "textarea" ? (
        <textarea
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
          rows={rows}
          className="p-2 rounded w-full"
        />
      ) : type === "file" ? (
        <>
          <button
            type="button"
            onClick={onClick}
            className="submit px-5 py-1.5"
          >
            {label}
          </button>
          <input
            type="file"
            name={name}
            onChange={onChange}
            required={required}
            className="hidden" // Cache le champ d'input de fichier
          />
        </>
      ) : (
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
          className="w-full p-2 rounded"
        />
      )}
    </div>
  </div>
);

export default InputField;
