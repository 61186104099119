import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import Anchor from "../utilities/Anchor.js";
import Modal from "../Modal.js";
import UsersListView from "./UsersListView.js";
import { useNavigate } from "react-router-dom";

const UsersList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [filterRole, setFilterRole] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalState, setModalState] = useState({ isOpen: false, user: null });
  const [columns, setColumns] = useState([
    { key: "name", label: t("name"), selected: true },
    { key: "surname", label: t("surname"), selected: true },
    { key: "birthday", label: t("birthday"), selected: true },
    { key: "role", label: t("role"), selected: true },
    { key: "team", label: t("team"), selected: true },
    { key: "discord_tag", label: "Discord", selected: false },
    { key: "twitter_login", label: "Twitter", selected: false },
    { key: "twitch_login", label: "Twitch", selected: false },
    { key: "riot_login", label: "Riot", selected: false },
    { key: "epic_login", label: "Epic", selected: false },
    { key: "created_at", label: t("created_at"), selected: true },
    { key: "address", label: t("adress"), selected: false },
    { key: "zip_code", label: t("zip_code"), selected: false },
    { key: "town", label: t("town"), selected: false },
    { key: "country", label: t("country"), selected: false },
  ]);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get("https://vps-e65866ce.vps.ovh.net:5000/api/users", {
        withCredentials: true,
      });
      setUsers(response.data);
    } catch {
      console.error("Erreur lors de la récupération des utilisateurs.");
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const openModal = (user = null) => setModalState({ isOpen: true, user });
  const closeModal = () => setModalState({ isOpen: false, user: null });

  const handleColumnToggle = (key) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) =>
        column.key === key ? { ...column, selected: !column.selected } : column
      )
    );
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  const handleViewProfile = (id) => {
    navigate(`/users/${id}`);
  };

  const handleModifyUser = (id) => {
    navigate(`/users/modify/${id}`);
  };

  const handleDeleteUser = async (id) => {
    const confirmDelete = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
    );
    if (!confirmDelete) return;

    try {
      console.log(`Suppression de l'utilisateur avec ID: ${id}`);
      await axios.delete(`https://vps-e65866ce.vps.ovh.net:5000/api/users/${id}`,{
        withCredentials: true,
      });
      console.log("Utilisateur supprimé avec succès.");
      fetchUsers();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'utilisateur", error);
    }
  };

  const getRandomColor = () => {
    const colors = [
      "bg-red-500",
      "bg-blue-500",
      "bg-green-500",
      "bg-yellow-500",
      "bg-purple-500",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const filteredUsers = users.filter((user) => {
    const matchesRole = filterRole ? user.role === filterRole : true;
    const matchesSearch = user.pseudo
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesRole && matchesSearch;
  });

  const totalUsers = filteredUsers.length;
  const totalPages = Math.ceil(totalUsers / itemsPerPage);
  const displayedUsers = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const from = (currentPage - 1) * itemsPerPage + 1;
  const to = Math.min(currentPage * itemsPerPage, totalUsers);

  return (
    <div>
      <Anchor titlePage={t("users_list")} />
      <div className="container-form-settings flex flex-col m-4 min-[1820px]:my-4 min-[1820px]:mx-auto surface-a10 rounded max-w-screen-2xl">
        <div className="flex flex-col p-6">
          <div className="flex justify-between items-center mb-4">
            <h5 className="font-bold mb-2">{t("filters")}</h5>
          </div>
          <div className="flex flex-wrap w-full">
            <select
              value={filterRole}
              onChange={(e) => setFilterRole(e.target.value)}
              className="border p-2 rounded w-full md:w-auto mb-2 md:mb-0"
            >
              <option value="">{t("select_role")}</option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
              <option value="guest">Guest</option>
            </select>
          </div>
        </div>
        <div className="users-filter container-form-settings flex-wrap justify-between items-center px-6 hidden md:flex">
          {columns.map(({ key, label, selected }) => (
            <button
              key={key}
              onClick={() => handleColumnToggle(key)}
              className={`px-4 py-2 mr-2 rounded ${
                selected ? "submit" : "button-form"
              }`}
            >
              {label}
            </button>
          ))}
        </div>
        <div className="users-filter container-form-settings flex flex-wrap justify-between items-center px-6">
          <select
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
            className="border p-2 rounded w-full md:w-auto mb-2 md:mb-0"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
          <input
            type="text"
            placeholder={t("search_username")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full md:w-auto"
          />
        </div>
        <div className="overflow-x-auto max-w-full">
          <table className="min-w-full table-auto">
            <thead>
              <tr>
                <th className="p-4 md:hidden visible"></th>
                <th className="p-4">{t("user")}</th>
                {columns.map(({ key, label, selected }) =>
                  selected ? (
                    <th key={key} className="p-4 hidden md:table-cell">
                      {label}
                    </th>
                  ) : null
                )}
                <th className="p-4 hidden md:table-cell">Actions</th>
              </tr>
            </thead>
            <tbody>
              {displayedUsers.map((user) => (
                <tr key={user._id}>
                  <td className="md:hidden visible">
                    <button
                      onClick={() => openModal(user)}
                      className="px-5 py-1.5 text-gray-400"
                    >
                      <Icon icon="mdi:plus" width="26" height="26" />
                    </button>
                  </td>
                  <td className="w-full md:w-max md:px-4 py-2 align-middle table-cell">
                    <div className="flex items-center">
                      {user.discord_avatar_url ? (
                        <img
                          src={user.discord_avatar_url}
                          alt={user.pseudo}
                          className="w-10 h-10 rounded-full mr-2"
                        />
                      ) : (
                        <div
                          className={`w-10 h-10 rounded-full mr-2 flex items-center justify-center ${getRandomColor()}`}
                        >
                          <span className="text-white font-bold">
                            {user.pseudo.slice(0, 2).toUpperCase()}
                          </span>
                        </div>
                      )}
                      <div className="leading-tight">
                        <div className="font-bold text-sm">{user.pseudo}</div>
                        <div className="text-sm text-gray-400">
                          {user.email}
                        </div>
                      </div>
                    </div>
                  </td>
                  {columns.map(({ key, selected }) =>
                    selected ? (
                      <td
                        key={key}
                        className="px-4 py-2 align-middle table-cell whitespace-nowrap hidden md:table-cell"
                      >
                        {key === "created_at" || key === "birthday"
                          ? formatDate(user[key])
                          : user[key] || "N/A"}
                      </td>
                    ) : null
                  )}
                  <td className="px-4 hidden md:table-cell py-2 align-middle">
                    <button
                      onClick={() => handleViewProfile(user._id)}
                      className="m-1 text-gray-400 hover:text-gray-300"
                      aria-label="View Profile"
                    >
                      <Icon icon="mdi:eye-outline" width="24" height="24" />
                    </button>
                    <button
                      onClick={() => handleModifyUser(user._id)}
                      className="m-1 text-gray-400 hover:text-gray-300"
                      aria-label="Edit User"
                    >
                      <Icon
                        icon="mdi:edit-box-outline"
                        width="24"
                        height="24"
                      />
                    </button>
                    <button
                      onClick={() => handleDeleteUser(user._id)}
                      className="m-1 text-gray-400 hover:text-gray-300"
                      aria-label="Delete User"
                    >
                      <Icon icon="mdi:remove-bold" width="24" height="24" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex flex-col md:flex-row justify-between p-4">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="button-form px-4 py-2 rounded mb-2 md:mb-0"
          >
            {t("previous")}
          </button>
          <div className="p-4 text-gray-500">
            <span>
              Showing {from} to {to} of {totalUsers} entries
            </span>
          </div>
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className="button-form px-4 py-2 rounded mb-2 md:mb-0"
          >
            {t("next")}
          </button>
        </div>
      </div>
      <Modal
        title={modalState.user ? `Profil de ${modalState.user.pseudo}` : ""}
        show={modalState.isOpen}
        onClose={closeModal}
      >
        <UsersListView
          user={modalState.user}
          onClose={closeModal}
          fetchUsers={fetchUsers}
        />
      </Modal>
    </div>
  );
};

export default UsersList;
