import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "./context/UserContext";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import Users from "./pages/Users";
import Profile from "./components/settings/Profile";
import Organization from "./components/settings/Organization";
import UsersList from "./components/users/UsersList";
import User from "./components/users/User";
import "./i18n";
import UserUpdate from "./components/users/UserUpdate";

function App() {
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get("https://vps-e65866ce.vps.ovh.net:5000/api/user", { withCredentials: true });
        setUser(response.data); // Met à jour l'utilisateur authentifié
      } catch (error) {
        console.error("Erreur lors de la récupération de l'utilisateur:", error);
      } finally {
        setLoading(false); // Fin du chargement
      }
    };

    fetchUser();
  }, [setUser]);

  if (loading) {
    return <div>Loading...</div>; // Affiche un message de chargement
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={!user ? <Home /> : <Navigate to="/dashboard" replace />}
        />
        <Route
          path="/dashboard"
          element={user ? <Dashboard /> : <Navigate to="/" replace />}
        />
        <Route
          path="/users"
          element={
            user && user.role === "admin" ? (
              <Users />
            ) : (
              <Navigate to="/" replace />
            )
          }
        >
          <Route path="list" element={<UsersList />} />
          <Route path=":id" element={<User />} />
          <Route path="modify/:id" element={<UserUpdate />} />
        </Route>
        <Route
          path="/settings"
          element={user ? <Settings /> : <Navigate to="/" replace />}
        >
          <Route path="profile" element={<Profile />} />
          <Route
            path="organization"
            element={
              user && user.role === "admin" ? (
                <Organization />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
        </Route>
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
