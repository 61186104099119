// context/UserContext.js
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

// Créer le contexte utilisateur
export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // État de chargement

  // Récupérer l'utilisateur authentifié
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get("https://vps-e65866ce.vps.ovh.net:5000/api/user", {
          withCredentials: true,
        });
        setUser(response.data); // Mettez à jour avec la réponse correcte
      } catch (error) {
        console.log("Vous n'êtes pas connecté");
        setUser(null); // Assurez-vous de définir user sur null en cas d'erreur
      } finally {
        setLoading(false); // Met à jour l'état de chargement
      }
    };

    fetchUser();
  }, []);

  // Ajoutez un effet pour suivre les changements de l'utilisateur
  useEffect(() => {
  }, [user]); // Cela se déclenchera chaque fois que `user` change

  return (
    <UserContext.Provider value={{ user, loading, setUser }}>
      {children}
    </UserContext.Provider>
  );
};