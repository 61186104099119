import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const UserProfile = ({ user }) => {
  const { t } = useTranslation();
  const [teams, setTeams] = useState([]);

  async function getUserRolesInAllTeams(userId) {
    try {
      const response = await axios.get(`https://vps-e65866ce.vps.ovh.net:5000/api/teams/check-all-roles/${userId}`, { withCredentials: true });
      console.log(response.data);
      return Array.isArray(response.data.roles) ? response.data.roles : [];
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des rôles de l'utilisateur :",
        error
      );
      return [];
    }
  }

  useEffect(() => {
    if (user && user._id) {
      getUserRolesInAllTeams(user._id).then((fetchedTeams) => {
        setTeams(fetchedTeams);
      });
    }
  }, [user]);

  if (!user) {
    return <div>{t("user_not_found")}</div>;
  }

  const aboutItems = [
    { icon: "mdi:user-outline", label: t("username"), value: user.pseudo },
    { icon: "mdi:crown-outline", label: t("role"), value: user.role },
    {
      icon: "mdi:flag-variant-outline",
      label: t("country"),
      value: user.country,
    },
  ];

  const contactItems = [
    { icon: "mdi:email-outline", label: t("email"), value: user.email },
  ];

  const InfoList = ({ items }) => (
    <ul className="font-medium text-[0.93rem] my-3 py-1">
      {items.map(({ icon, label, value }) => (
        <li key={label} className="flex items-center mx-2 mb-4">
          <Icon icon={icon} width="18" height="18" className="mr-1" />
          <span>{`${label}: ${value}`}</span>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="grid md:grid-cols-3 md:gap-4 m-4 min-[1820px]:my-4 min-[1820px]:mx-auto max-w-screen-2xl items-start">
      <div className="col-span-3 md:col-span-1">
        <div className="surface-a10 mb-4 p-4">
          <span className="ml-2 caption-text font-secondary primary-a50 !text-sm">
            {t("about")}
          </span>
          <InfoList items={aboutItems} />
          <span className="ml-2 caption-text font-secondary primary-a50 !text-sm">
            {t("contact")}
          </span>
          <InfoList items={contactItems} />
        </div>
        {teams.length > 0 && (
          <div className="surface-a10 mb-4 p-4">
            <span className="ml-2 caption-text font-secondary primary-a50 !text-sm">
              {t("team")}
            </span>
            <ul className="font-medium text-[0.93rem] my-3 py-1">
              {teams.map((team, index) => (
                <li key={index} className="flex items-center mx-2 mb-4">
                  <Icon
                    icon={team.icon}
                    width="18"
                    height="18"
                    className="mr-2"
                  />
                  <span>{`${team.teamName} - ${team.role}`}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="col-span-3 md:col-span-2">
        <div className="surface-a10 mb-4 p-4">
          <div className="flex items-center mb-2">
            <Icon
              icon="mdi:gamepad-variant-outline"
              width="20"
              height="20"
              className="mr-4"
            />
            <h1 className="font-bold text-xl">{t("activity_timeline")}</h1>
          </div>
          <div>{t("coming_soon")}</div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
