import React, { useEffect, useState } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

const Banner = ({ user }) => {
  const [roles, setRoles] = useState({});
  const { t } = useTranslation();

  const getRandomColor = () => {
    const colors = [
      "bg-red-500",
      "bg-blue-500",
      "bg-green-500",
      "bg-yellow-500",
      "bg-purple-500",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const formatDate = (dateString) => {
    const options = { month: "long", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "fr-FR",
      options
    );
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  };

  const gradients = [
    "bg-gradient-to-r from-purple-500 via-pink-500 to-red-500",
    "bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600",
    "bg-gradient-to-r from-green-400 via-teal-500 to-blue-500",
    "bg-gradient-to-r from-yellow-500 via-orange-500 to-red-500",
    "bg-gradient-to-r from-pink-400 via-purple-500 to-blue-600",
  ];

  const randomGradient =
    gradients[Math.floor(Math.random() * gradients.length)];

  useEffect(() => {
    // Récupérer les rôles depuis l'API
    const fetchRoles = async () => {
      try {
        const response = await axios.get("https://vps-e65866ce.vps.ovh.net:5000/api/roles", { withCredentials: true });
        setRoles(response.data.roles); // Supposons que les données sont dans le format { roles: { ... } }
      } catch (error) {
        console.error("Erreur lors de la récupération des rôles:", error);
      }
    };

    fetchRoles();
  }, []);

  return (
    <div className="relative m-4 min-[1820px]:my-4 min-[1820px]:mx-auto max-w-screen-2xl">
      <div
        className={`w-full h-20 md:h-48 ${randomGradient} text-white flex items-center justify-center text-2xl font-bold rounded`}
      />

      <div>
        <div className="flex flex-col md:flex-row md:items-center surface-a10 shadow-lg p-4">
          <div className="flex justify-center md:justify-start w-full md:w-auto">
            {user.discord_avatar_url ? (
              <img
                src={user.discord_avatar_url}
                alt={user.pseudo}
                className="w-[100px] h-[100px] rounded-full -mt-12 border-4 border-surface-a10"
              />
            ) : (
              <div
                className={`w-[100px] h-[100px] rounded-full flex items-center justify-center ${getRandomColor()} -mt-12 border-4 border-surface-a10`}
              >
                <span className="text-white font-bold text-2xl">
                  {user.pseudo.slice(0, 2).toUpperCase()}
                </span>
              </div>
            )}
          </div>
          <div className="text-center md:text-left mt-4 md:mt-0 md:ml-4">
            <div className="font-bold text-xl">
              {user.name && user.surname
                ? user.surname + " " + user.name
                : user.pseudo}
            </div>
            <ul className="text-sm py-2 text-gray-400 text-base flex flex-col md:flex-row md:items-center">
              {user.role && roles[user.role] ? (
                <>
                  <li className="flex items-center justify-center text-[15px] md:mr-5 mb-2 md:mb-0">
                    <Icon
                      icon={roles[user.role].icon}
                      width="24"
                      className="mr-1"
                    />
                    {roles[user.role].name}
                  </li>
                  <li className="flex items-center justify-center text-[15px] md:mr-5 mb-2 md:mb-0">
                    <Icon
                      icon="mdi:location-on-outline"
                      width="24"
                      className="mr-1"
                    />
                    {user.country}
                  </li>
                  <li className="flex items-center justify-center text-[15px] md:mr-5">
                    <Icon
                      icon="mdi:calendar-blank"
                      width="24"
                      className="mr-1"
                    />
                    {t("joined") + " " + formatDate(user.created_at)}
                  </li>
                </>
              ) : (
                <li>Rôle inconnu</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
