import React, { useEffect, useState } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";

const Alerts = ({ user, organization }) => {
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    // Récupérer les alertes via l'API
    axios
      .get("https://vps-e65866ce.vps.ovh.net:5000/api/alerts", { withCredentials: true })
      .then((response) => setAlerts(response.data))
      .catch((error) => console.error("Error fetching alerts:", error));
  }, []);

  // Fonction pour supprimer une alerte
  const removeAlert = (id) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert._id !== id));
  };

  return (
    <div>
      {alerts.map((alert) => {
        // Évaluation de la condition
        if (eval(alert.condition) && alert.statut === "active") {
          // Utilisation de eval pour vérifier la condition
          const alertTypeClasses = {
            warn: "bg-red-600",
            success: "bg-green-600",
            infos: "bg-blue-600",
          };

          return (
            <div
              role="alert"
              className={`mb-4 relative flex w-full p-3 text-sm text-white rounded-md z-10 ${
                alertTypeClasses[alert.type]
              }`}
              key={alert._id}
            >
              <div dangerouslySetInnerHTML={{ __html: alert.message }}></div>
              <button
                className="button-alert flex items-center justify-center transition-all w-8 h-8 rounded-md text-white hover:bg-white/10 active:bg-white/10 absolute top-1.5 right-1.5"
                type="button"
                onClick={() => removeAlert(alert._id)} // Appel de la fonction pour supprimer l'alerte
              >
                <Icon icon="mdi:remove-bold" width="32" height="32" />
              </button>
            </div>
          );
        }
        return null; // Ne retourne rien si la condition n'est pas remplie
      })}
    </div>
  );
};

export default Alerts;
