import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import InputField from "../utilities/InputField"; // Assurez-vous que le chemin est correct

const initialFormData = {
  message: "",
  condition: "",
  type: "",
  statut: "",
};

const OrganizationAlertsCreate = ({ onClose, fetchAlerts }) => {
  const [formData, setFormData] = useState(initialFormData);
  const { t } = useTranslation();

  const handleChange = ({ target: { name, value } }) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://vps-e65866ce.vps.ovh.net:5000/api/alerts", formData);
      fetchAlerts();
      onClose();
    } catch (error) {
      console.error("Erreur lors de la création de l'alerte :", error);
    }
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    onClose();
  };

  return (
    <div className="mx-auto w-full">
      <form onSubmit={handleSubmit} className="flex flex-col">
        <div className="flex flex-wrap">
          <InputField
            label={t("type")}
            name="type"
            value={formData.type}
            onChange={handleChange}
            required
            type="select"
            options={[
              { value: "success", label: t("success") },
              { value: "warn", label: t("warn") },
              { value: "infos", label: t("infos") },
            ]}
            placeholder={t("select_type")}
          />
          <InputField
            label={t("statut")}
            name="statut"
            value={formData.statut}
            onChange={handleChange}
            required
            type="select"
            options={[
              { value: "active", label: t("active") },
              { value: "inactive", label: t("inactive") },
            ]}
            placeholder={t("select_statut")}
          />
        </div>
        <InputField
          label={t("condition")}
          name="condition"
          value={formData.condition}
          onChange={handleChange}
          required
          placeholder="!user.name"
        />
        <InputField
          label={t("message")}
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          type="textarea"
        />
        <div className="flex space-x-4">
          <button type="submit" className="submit px-5 py-1.5">
            {t("create_alert")}
          </button>
          <button
            type="button"
            className="cancel-button button-form px-5 py-1.5"
            onClick={handleCancel}
          >
            {t("cancel")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default OrganizationAlertsCreate;
