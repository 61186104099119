import React, { useState, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import InputField from "../utilities/InputField";

const OrganizationLogoUpload = ({ onChange, linkImage }) => {
  const { t } = useTranslation();
  const initialLogoUrl = linkImage
    ? `https://vps-e65866ce.vps.ovh.net:5000/${linkImage}`
    : null;
  const [previewUrl, setPreviewUrl] = useState(initialLogoUrl);
  const fileInputRef = useRef(null);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreviewUrl(URL.createObjectURL(file)); // Prépare l'aperçu

      // Créer un nouveau nom de fichier
      const newFileName = `logo${file.name.substring(
        file.name.lastIndexOf(".")
      )}`;
      const newFile = new File([file], newFileName, { type: file.type });

      const formData = new FormData();
      formData.append("logo", newFile);

      try {
        const response = await axios.post("https://vps-e65866ce.vps.ovh.net:5000/api/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        console.log("Upload réussi :", response.data);
        onChange(newFile.name); // Mise à jour du nom du fichier
      } catch (error) {
        console.error("Erreur lors du téléchargement :", error);
      }
    }
  };

  const triggerFileInput = () => fileInputRef.current.click();

  return (
    <div className="flex flex-col mb-4">
      <div className="flex items-center space-x-4">
        <div>
          {previewUrl ? (
            <img
              src={previewUrl}
              alt="Aperçu du logo"
              className="w-20 h-20 object-cover rounded"
            />
          ) : (
            <div className="w-20 h-20 bg-gray-200 rounded flex items-center justify-center text-gray-500">
              {t("preview")}
            </div>
          )}
        </div>
        <div className="flex flex-col items-start">
          <InputField
            label={t("upload_logo")}
            name="logo"
            type="file"
            onChange={handleFileChange}
            onClick={triggerFileInput}
          />
          <p className="mt-1 text-xs text-white">{t("upload_format")}</p>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }} // Cache l'input de fichier
        onChange={handleFileChange}
      />
    </div>
  );
};

export default OrganizationLogoUpload;
