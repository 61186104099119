import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import InputField from "../utilities/InputField";
import { Icon } from "@iconify/react/dist/iconify.js";

const initialFormData = {
  name: "",
  type: "",
  game: "",
  icon: "",
  managers: [],
  players: [],
  coachs: [],
};

const OrganizationTeamsUpdate = ({ team, onClose, fetchTeams }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/api/users", {
          withCredentials: true,
        });
        setUsers(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des utilisateurs :",
          error
        );
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    if (team) setFormData({ ...initialFormData, ...team });
  }, [team]);

  const handleChange = ({ target: { name, value } }) =>
    setFormData((prevData) => ({ ...prevData, [name]: value }));

  const handleAdd = (field) =>
    setFormData((prevData) => ({
      ...prevData,
      [field]: [...prevData[field], ""],
    }));

  const handleRemove = (field, index) =>
    setFormData((prevData) => ({
      ...prevData,
      [field]: prevData[field].filter((_, i) => i !== index),
    }));

  const handleSelectChange = (field, index, value) =>
    setFormData((prevData) => ({
      ...prevData,
      [field]: prevData[field].map((item, i) => (i === index ? value : item)),
    }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://vps-e65866ce.vps.ovh.net:5000/api/teams/${team._id}`, formData);
      fetchTeams();
      onClose();
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'équipe :", error);
    }
  };

  const handleCancel = () => setFormData(team || initialFormData);

  const SelectInput = ({ field, label }) => (
    <div className="flex flex-col px-3 space-y-2 mb-5">
      <label className="block text-xs font-medium px-0.5 py-1">{label}</label>
      <div className="flex flex-wrap mt-1">
        {formData[field].map((item, index) => (
          <div key={index} className="flex items-center space-x-2">
            <select
              value={item}
              onChange={(e) => handleSelectChange(field, index, e.target.value)}
              className="p-2 rounded"
              required
            >
              <option value="">{t("select_user")}</option>
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.pseudo}
                </option>
              ))}
            </select>
            <button
              type="button"
              className="color-primary"
              onClick={() => handleRemove(field, index)}
            >
              <Icon icon="mdi:close" />
            </button>
          </div>
        ))}
      </div>
      <button type="button" onClick={() => handleAdd(field)}>
        {t(`add_${field.slice(0, -1)}`)}
      </button>
    </div>
  );

  return (
    <div className="mx-auto w-full">
      <form onSubmit={handleSubmit} className="flex flex-col">
        <InputField
          label={t("team_name")}
          name="name"
          type="text"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <InputField
          label={t("icon")}
          name="icon"
          type="text"
          value={formData.icon}
          onChange={handleChange}
          required
        />

        <InputField
          label={t("type")}
          name="type"
          type="select"
          value={formData.type}
          onChange={handleChange}
          required
          options={[
            { value: "", label: t("select_type") },
            { value: "transversal", label: t("transversal") },
            { value: "esport", label: t("esport") },
          ]}
        />

        {formData.type === "esport" && (
          <InputField
            label={t("game")}
            name="game"
            type="select"
            value={formData.game}
            onChange={handleChange}
            required
            options={[
              { value: "", label: t("select_game") },
              { value: "leagueoflegends", label: "League of Legends" },
              { value: "valorant", label: "Valorant" },
              { value: "fortnite", label: "Fortnite" },
            ]}
          />
        )}

        <SelectInput field="managers" label={t("managers")} />
        <SelectInput field="players" label={t("players")} />
        <SelectInput field="coachs" label={t("coachs")} />

        <div className="flex space-x-4">
          <button type="submit" className="submit px-5 py-1.5">
            {t("save_changes")}
          </button>
          <button
            type="button"
            className="cancel-button button-form px-5 py-1.5"
            onClick={handleCancel}
          >
            {t("cancel")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default OrganizationTeamsUpdate;
