import React from "react";
import { Icon } from "@iconify/react";

const Tabs = ({ tabs, activeTab, setActiveTab }) => (
  <ul className="flex">
    {tabs.map(({ key, icon, label }) => (
      <li
        key={key}
        className={`cursor-pointer mr-1 flex items-center ${
          activeTab === key ? "active" : ""
        }`}
        onClick={() => setActiveTab(key)}
      >
        <Icon icon={icon} height="24" className="mr-2" />
        {label}
      </li>
    ))}
  </ul>
);

export default Tabs;
