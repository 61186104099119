import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const UserGames = ({ user }) => {
  const [ranks, setRanks] = useState([]);
  const [games, setGames] = useState([]);
  const [visibleGames, setVisibleGames] = useState(5);
  const { t } = useTranslation();

  // Fonction utilitaire pour calculer le temps écoulé
  const timeAgo = (timestamp) => {
    const timeInMs = Date.parse(timestamp);
    if (isNaN(timeInMs)) return t("timeAgo.invalidDate");

    const now = Date.now();
    const secondsElapsed = Math.floor((now - timeInMs) / 1000);

    if (secondsElapsed < 60)
      return t("timeAgo.seconds", { count: secondsElapsed });
    const minutes = Math.floor(secondsElapsed / 60);
    if (minutes < 60) return t("timeAgo.minutes", { count: minutes });
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return t("timeAgo.hours", { count: hours });
    const days = Math.floor(hours / 24);
    return t("timeAgo.days", { count: days });
  };

  const RankCard = ({ rank }) => (
    <div className="mb-4">
      <div className="flex items-center">
        <div className="mr-4 bg-zinc-700 p-3 rounded-full">
          <img
            src={`/icons/ranks/lol/${rank.rankData.lol.tier}.png`}
            alt={rank.rankData.lol.tier}
            className="w-20 h-20"
          />
        </div>
        <div className="flex-grow">
          <p className="text-1xl font-bold">{rank.gameName}</p>
          <p className="text-xl font-bold">
            {rank.rankData.lol.tier} {rank.rankData.lol.division}
          </p>
          <p className="text-lg">{rank.rankData.lol.leaguePoints} LP</p>
        </div>
        <div className="text-right">
          <p>
            {rank.rankData.lol.wins}V {rank.rankData.lol.losses}D
          </p>
          <p>
            {t("win_rate") + " "}
            {Math.floor(
              (rank.rankData.lol.wins /
                (rank.rankData.lol.wins + rank.rankData.lol.losses)) *
                100
            )}
            %
          </p>
        </div>
      </div>
    </div>
  );

  const GameCard = ({ game, participant }) => (
    <div
      className={`mb-1 flex items-center justify-between bg-gray-800 text-white p-4 rounded-lg w-full mx-auto ${
        participant.win ? "bg-gray-800" : "bg-red-900"
      }`}
    >
      <div className="flex flex-col space-y-2 w-2/3">
        <div className="flex items-center space-x-2 text-sm">
          <span
            className={`font-semibold ${
              participant.win ? "text-blue-400" : "text-red-400"
            }`}
          >
            {t("ranked_solo_duo_q")}
          </span>
          <span className="text-gray-400">
            {timeAgo(game.gameStartTimestamp)}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <img
            src={`https://ddragon.leagueoflegends.com/cdn/13.1.1/img/champion/${participant.championName}.png`}
            alt={`${participant.championName} Icon`}
            className="w-10 h-10 rounded-full"
          />
          <div className="text-lg font-bold text-yellow-400">
            {participant.kills} / {participant.deaths} / {participant.assists}
          </div>
          <div className="text-gray-400">
            {participant.deaths === 0
              ? t("perfect_kda")
              : ((participant.kills + participant.assists) / participant.deaths)
                  .toFixed(2)
                  .replace(/\.?0+$/, "") + " KDA"}
          </div>
        </div>
        <div className="flex items-center space-x-1 text-sm">
          <span className={`text-gray-400 font-semibold`}>
            {participant.win ? t("win") : t("defeat")}
          </span>
          <span>
            {`${Math.floor(game.gameDuration / 60)}:${(game.gameDuration % 60)
              .toString()
              .padStart(2, "0")}`}
          </span>
        </div>
        <div className="flex space-x-1">
          {participant.items.filter(Boolean).map((item, idx) => (
            <img
              key={idx}
              src={`https://ddragon.leagueoflegends.com/cdn/13.1.1/img/item/${item}.png`}
              alt="Item"
              className="w-8 h-8"
            />
          ))}
        </div>
      </div>
      <div className="w-1/3 flex flex-col items-end space-y-2 hidden md:block">
        <div className="flex flex-wrap justify-end text-sm text-gray-300">
          {game.participants.map((p, i) => (
            <div key={i} className="w-1/2 flex items-center space-x-2">
              <img
                src={`https://ddragon.leagueoflegends.com/cdn/13.1.1/img/champion/${p.championName}.png`}
                alt={`${p.championName} Icon`}
                className="w-6 h-6 rounded-full"
              />
              <span>{p.summonerName || "(undefined)"}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (user && user._id) {
      Promise.all([
        axios.get(`https://vps-e65866ce.vps.ovh.net:5000/api/usersranks/${user._id}`, { withCredentials: true }),
        axios.get(`https://vps-e65866ce.vps.ovh.net:5000/api/lol/gameshistories/${user._id}`, { withCredentials: true }),
      ])
        .then(([ranksRes, gamesRes]) => {
          setRanks(ranksRes.data);
          setGames(gamesRes.data);
        })
        .catch((error) =>
          console.error("Erreur de récupération des données : ", error)
        );
    }
  }, [user]);

  if (!user) return <div>User not found.</div>;

  // Fonction pour afficher plus de parties
  const showMoreGames = () => {
    setVisibleGames((prev) => prev + 5);
  };

  return (
    <div className="grid md:grid-cols-3 md:gap-4 m-4 min-[1820px]:my-4 min-[1820px]:mx-auto max-w-screen-2xl items-start">
      <div className="col-span-3 md:col-span-1">
        <div className="surface-a10 mb-4 p-4">
          <div className="flex items-center mb-2">
            <Icon
              icon="mdi:gamepad-variant-outline"
              width="20"
              height="20"
              className="mr-4"
            />
            <h1 className="font-bold text-xl">Classement</h1>
          </div>
          <div>
            {ranks.length > 0 ? (
              ranks.map((rank, index) => <RankCard key={index} rank={rank} />)
            ) : (
              <p>Loading user rank...</p>
            )}
          </div>
        </div>
      </div>
      <div className="col-span-3 md:col-span-2">
        <div className="surface-a10 mb-4 p-4">
          <h1 className="font-bold text-xl mb-2">
            Historique des parties classées
          </h1>
          <div>
            {games
              .slice(0, visibleGames)
              .map((game, index) =>
                game.participants
                  .filter((participant) => participant.puuid === game.puuid)
                  .map((participant, i) => (
                    <GameCard key={i} game={game} participant={participant} />
                  ))
              )}
          </div>
          {visibleGames < games.length && (
            <div className="flex justify-end">
              <button
                onClick={showMoreGames}
                className="mt-4 px-4 py-2 submit rounded-md"
              >
                Afficher plus
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserGames;
