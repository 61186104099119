import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "../Modal";
import { useTranslation } from "react-i18next";
import OrganizationTeamsCreate from "./OrganizationTeamsCreate";
import OrganizationTeamsUpdate from "./OrganizationTeamsUpdate";
import axios from "axios";

const OrganizationTeams = () => {
  const { t } = useTranslation();
  const [teams, setTeams] = useState([]);
  const [filterType, setFilterType] = useState("all");
  const [filterGame, setFilterGame] = useState("all");
  const [modalState, setModalState] = useState({ isOpen: false, team: null });

  const fetchTeams = useCallback(async () => {
    try {
      const response = await axios.get("https://vps-e65866ce.vps.ovh.net:5000/api/teams", { withCredentials: true });
      setTeams(response.data);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  }, []);

  useEffect(() => {
    fetchTeams();
  }, [fetchTeams]);

  const filteredTeam = teams.filter(
    (team) =>
      (filterType === "all" || team.type === filterType) &&
      (filterGame === "all" || team.game === filterGame)
  );

  const openModal = (team = null) => setModalState({ isOpen: true, team });
  const closeModal = () => setModalState({ isOpen: false, team: null });

  const handleDelete = useCallback(async (id) => {
    try {
      await axios.delete(`https://vps-e65866ce.vps.ovh.net:5000/api/teams/${id}`);
      setTeams((prevTeams) => prevTeams.filter((team) => team._id !== id));
    } catch (error) {
      console.error("Error deleting team:", error);
    }
  }, []);

  const filterOptions = {
    type: [
      { value: "all", label: t("all_types") },
      { value: "transversal", label: t("transversal") },
      { value: "esport", label: t("esport") },
    ],
    game: [
      { value: "all", label: t("all_games") },
      { value: "leagueoflegends", label: t("leagueoflegends") },
      { value: "valorant", label: t("valorant") },
      { value: "fortnite", label: t("fortnite") },
    ],
  };

  const FilterSelect = ({ value, onChange, options }) => (
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="w-full md:w-1/3 md:mr-4 mb-2"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );

  const TeamActions = ({ team }) => (
    <>
      <button
        onClick={() => openModal(team)}
        className="m-1 text-gray-400 hover:text-gray-300"
      >
        <Icon icon="mdi:square-edit-outline" />
      </button>
      <button
        onClick={() => handleDelete(team._id)}
        className="m-1 text-gray-400 hover:text-gray-300"
      >
        <Icon icon="mdi:remove-bold" />
      </button>
    </>
  );

  return (
    <div className="container-form-settings flex flex-col m-4 min-[1820px]:my-4 min-[1820px]:mx-auto surface-a10 rounded max-w-screen-2xl">
      <div className="flex flex-col p-6">
        <div className="flex justify-between items-center mb-4">
          <h5 className="font-bold mb-2">{t("filters")}</h5>
          <button
            onClick={() => openModal()}
            className="submit px-3 py-1.5 flex"
          >
            <Icon icon="mdi:plus" height="26" className="mr-2" />
            {t("add_team")}
          </button>
        </div>
        <div className="flex flex-wrap w-full">
          <FilterSelect
            value={filterType}
            onChange={setFilterType}
            options={filterOptions.type}
          />
          <FilterSelect
            value={filterGame}
            onChange={setFilterGame}
            options={filterOptions.game}
          />
        </div>
      </div>
      <table className="min-w-full">
        <thead>
          <tr>
            <th className="p-4">{t("team")}</th>
            <th className="p-4 hidden md:table-cell">{t("type")}</th>
            <th className="p-4 hidden md:table-cell">{t("game")}</th>
            <th className="p-4 hidden md:table-cell">{t("managers")}</th>
            <th className="p-4 hidden md:table-cell">{t("coachs")}</th>
            <th className="p-4 hidden md:table-cell">{t("players")}</th>
            <th className="p-4">{t("actions")}</th>
          </tr>
        </thead>
        <tbody>
          {filteredTeam.map((team) => (
            <tr key={team._id}>
              <td className="px-4 py-2">{team.name}</td>
              <td className="px-4 py-2 hidden md:table-cell">{t(team.type)}</td>
              <td className="px-4 py-2 hidden md:table-cell">{t(team.game)}</td>
              <td className="px-4 py-2 hidden md:table-cell">
                {team.managers.length}
              </td>
              <td className="px-4 py-2 hidden md:table-cell">
                {team.coachs.length}
              </td>
              <td className="px-4 py-2 hidden md:table-cell">
                {team.players.length}
              </td>
              <td className="px-4 py-2 text-xl text-center">
                <TeamActions team={team} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        title={modalState.team ? t("modify_team") : t("add_team")}
        show={modalState.isOpen}
        onClose={closeModal}
      >
        {modalState.team ? (
          <OrganizationTeamsUpdate
            team={modalState.team}
            onClose={closeModal}
            fetchTeams={fetchTeams}
          />
        ) : (
          <OrganizationTeamsCreate
            onClose={closeModal}
            fetchTeams={fetchTeams}
          />
        )}
      </Modal>
    </div>
  );
};

export default OrganizationTeams;
